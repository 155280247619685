@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&Sen:wght@400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@font-face {
  font-family: ModerneSans;
  src: url(./fonts/ModerneSans/MODERNE_SANS.ttf);
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  /* font-family: ModerneSans, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

:root {
  --nav-height: 56px;
  --section-padding: 8rem 10rem;

  --h1: 9.6rem;
  --h2: 4.8rem;
  --h3: 4rem;
  --h4: 3rem;

  --gradient-1: linear-gradient(165deg,#c2c2c266, #8585857e);

  --shadow-1: 0px 9px 4px rgba(0, 0, 0, 0.25);
  --shadow-2: 0px 5px 4px rgba(0, 0, 0, 0.25);
  --shadow-3: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

@media (max-width: 1024px) {
  :root {
    --section-padding: 6rem 8rem;
  }

  html {
    font-size: 50%;
  }
}

@media (max-width: 640px) {
  :root {
    --section-padding: 6rem 4rem;
  }

  html {
    font-size: 40%;
  }
}
@media (max-width: 350px) {
  :root {
    --section-padding: 4rem 2rem;
  }

  html {
    font-size: 30%;
  }
}
